export const TICK = "TICK";
export const ROUTE_CHANGED = "route/ROUTE_CHANGED";
export const LOADING = "LOADING";
export const INITIALIZE = "INITIALZE";

export const NOTES_UPDATE = "NOTES_UPDATE";
export const NOTES_CLEAR = "NOTES_CLEAR";
export const FIELD_NOTES_UPDATE = "FIELD_NOTES_UPDATE";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export const DATA_EVENTS_UPDATE = "DATA_EVENTS_UPDATE";
export const DATA_EVENT_APPEND = "DATA_EVENT_APPEND";

// VIZ
export const RESET_VIZ_STATION_SENSOR_SELECTION = "RESET_VIZ_STATION_SENSOR_SELECTION";
export const UPDATE_VIZ_STATION = "UPDATE_VIZ_STATION";
export const UPDATE_VIZ_SENSOR = "UPDATE_VIZ_SENSOR";

export const SET_REFRESH_WORKSPACE_FN = "SET_REFRESH_WORKSPACE_FN";
