var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"map-wrap",class:{ 'hide-markers': !_vm.showStations }},[(!_vm.isCustomisationEnabled())?[(_vm.showHeader)?_c('StationsMapHeader',{attrs:{"project":_vm.project}}):_vm._e(),(_vm.showSidebar)?_c('StationsMapSidebar',{attrs:{"mapped":_vm.mapped,"stations":_vm.filteredStations},on:{"update-results-based-on-map":_vm.getStationsForBounds,"select-station":function($event){return _vm.$emit('show-summary', { id: $event })},"toggle":function($event){return _vm.handleLayoutChanges()}}}):_vm._e(),_vm._t("default")]:_vm._e(),_c('mapbox',{staticClass:"stations-map",attrs:{"access-token":_vm.mapbox.token,"map-options":{
            style: _vm.mapbox.style,
            bounds: _vm.bounds,
            zoom: 10,
        },"nav-control":{
            show: !_vm.isMobileView,
            position: 'bottom-left',
        }},on:{"map-init":_vm.onMapInitialized,"map-load":_vm.onMapLoaded,"zoomend":_vm.newBounds,"dragend":_vm.newBounds}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }